import React from "react"
import Artworks from "./Artworks.js"

class Gallery extends React.Component {
  constructor(props) {
    super(props)

    this.state = { 
      page : 1
    }

    this.change_page = this.change_page.bind(this)
  }

  change_page(x) {
    let last_page = this.state.page
    let next_page = this.state.page + x
    if (next_page >= 1 && next_page <= 5) {
      this.setState({
        page: next_page
      })
      if (next_page !== last_page) {
        window.scrollTo({top: 0,behavior: "smooth"})
      }
    }
  }

  render() {
    return (
      <div className="Gallery">
        <Artworks page={this.state.page}/>
        <div className="PageNavigation">
          <div className={this.state.page === 1 ? "PageButtonDisabled" : "PageButton"}>
            <button onClick={()=> this.change_page(-1)}>
              back
            </button>
          </div>
          <div className={this.state.page === 5 ? "PageButtonDisabled" : "PageButton"}>
            <button onClick={()=> this.change_page(1)}>
              next
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Gallery