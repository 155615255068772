import "./App.css"
import React from "react"
import Navbar from "./components/Navbar.js"
import Gallery from "./components/Gallery.js"
import About from "./components/About.js"
import Privacy from "./components/Privacy.js"
import TermsOfUse from "./components/TermsOfUse.js"

class Application extends React.Component {
  constructor(props) {
    super(props)
    this.state = { view : 1 }
    this.change_view = this.change_view.bind(this)
  }

  change_view(x) {
    this.setState({
      view: x
    })
  }
  
  render() {
    let content
    if (this.state.view === 1) {
      content = <Gallery/>
    }
    if (this.state.view === 2) {
      content = <About/>
    }
    if (this.state.view === 3) {
      content = <TermsOfUse/>
    }
    if (this.state.view === 4) {
      content = <Privacy/>
    }
    return (
      <div className="App">
        <Navbar change_view={this.change_view}/>
        {content}
      </div>
    )
  }
}

function App() {
  return (
    <Application/>
  )
}

export default App
