import React from "react"

class Privacy extends React.Component {
  render() {
    return (
      <div className="Legal">
        <div className="LegalContainer">
          <div className="LegalTitle">
          Privacy Policy
          </div>
          <div className="LegalHeader">
          This privacy policy (“Policy”) describes how Infinethum (“we”, “our”, or “us”) collects, uses, shares, and stores personal information of users of our website, https://infinethum.xyz - including any subdomains (collectively the “Application”).
          </div>
          <div className="LegalSection">
          How do we define personal data?
          </div>
          <div className="LegalText">
          We consider personal data to be any information that could be used to identify an individual.
          </div>
          <div className="LegalSection">
          What personal data do we intentionally collect?
          </div>
          <div className="LegalText">
          We do not intentionally collect any personal data of our users. Our Application is a decentralized application that merely facilitates your interaction with the Ethereum blockchain. We do not create, manage, or store user accounts or user information. We do not record or store your Ethereum address or any actions you perform on the blockchain.
          </div>
          <div className="LegalSection">
          What information is automatically stored?
          </div>
          <div className="LegalText">
          We may automatically record certain information about how you use our Application including but not limited to your Internet Protocol (IP) address, device and browser type, operating system, and other usage statistics. These data points are automatically logged by our web hosting and service providers. 
          </div>
          <div className="LegalSection">
          What information might other third parties collect?
          </div>
          <div className="LegalText">
          You may need to use MetaMask as your Ethereum provider to use the Application. We do not receive, collect, or store, nor are we responsible for any personal data or information that MetaMask may collect. You should refer to their <a className="LegalLink" href="https://metamask.io/privacy.html">privacy policy</a> for more information.
          </div>
          <div className="LegalSection">
          Sharing of personal information.
          </div>
          <div className="LegalText">
          We do not share any personal data or information with third parties.
          </div>
        </div>
      </div>
    )
  }
}

export default Privacy