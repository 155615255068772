import React from "react"

const variants = {
  0: "⚀",
  1: "⚁",
  2: "⚂"
}

class Artwork extends React.Component {

  constructor(props) {
    super(props)

    this.state = { 
      variant : 0
    }

    this.change_variant = this.change_variant.bind(this)
  }

  change_variant() {
    this.setState({
      variant: (this.state.variant + 1) % 3
    })
  }

  render() {
    const number = this.props.number;
    return (
      <div className="Artwork">
        { this.state.variant === 0 && (
          <img 
            className="ArtworkImage" 
            src={`https://d2hjejb7ft1tqy.cloudfront.net/${number}.png`}
            width="100%"
            height="100%" 
            alt={number}
          />
        )}
        { this.state.variant !== 0 && (
          <iframe
            title={number}
            width="100%"
            height="100%"
            frameBorder="0"
            src={`https://api.infinethum.xyz/variant/${number}/${this.state.variant}`}
          />
        )}
        <div className="ArtworkVariantToggle">
          <button onClick={() => this.change_variant()}>{variants[this.state.variant]}</button>
        </div>
      </div>
    )
  }
}

class Artworks extends React.Component {
  render() {
    let v = Array(20).fill().map((e, i) => i+(this.props.page-1)*20)
    const artwork = v.map((x, i) => <Artwork key={i} number={x}/>)
    return (
      <div className="Artworks">{artwork}</div>
    )
  }
}

export default Artworks