import React from "react"

class Navbar extends React.Component {
  render() {
    return (
      <div className="Navbar">
        <div className="NavbarTitle">
         Infinethum
        </div>
        <div className="NavbarSubTitle">
        - a generative art experience
        </div>
        <div className="NavbarLinks">
          <div className="NavbarLink">
            <button onClick={() => this.props.change_view(1)}>Gallery</button>
          </div>
          <div className="NavbarLink">
            <button onClick={() => this.props.change_view(2)}>About</button>
          </div>
          <div className="NavbarLink">
            <button onClick={() => window.open("https://generator.pxlq.art/infinethum")}>Generator</button>
          </div>
          <div className="NavbarLink">
            <button onClick={() => this.props.change_view(3)}>Terms of Use</button>
          </div>
          <div className="NavbarLink">
            <button onClick={() => this.props.change_view(4)}>Privacy</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar
